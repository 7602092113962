import { createMessages } from './hooks/useI18n';

export const { messages, defaultTranslations } = createMessages({
  shared: {
    expandTable: 'Expand table',
    quantity: 'Quantity',
    price: 'Price',
    update: 'Update',
    search: 'Search',
    searchArticleNumber: 'Product',
    searchPlaceholder: 'Search ...',
    searchName: 'Name ...',
    searchArticleNoPlaceholder: 'Product number ...',
    searchErpIdNamePlaceholder: 'Erp id or name ...',
    searchEmailNamePlaceholder: 'Email or name ...',
    excludeCustomerTypePlaceholder: 'Customer type ...',
    excludeCustomerTypeTitle: 'Exclude customer type(s)',
    shrinkTable: 'Shrink table',
    login: 'Login',
    logout: 'Logout',
    development: 'Development',
    settings: 'Settings',
    widgetsAccess: 'Widget access',
    otherInformation: 'Other information',
    noDataAvailable: 'No data available',
    details: 'Details',
    showDetails: 'Show details',
    hideDetails: 'Hide details',
    hide: 'Hide',
    loadMore: 'Load more',
    noItems: 'No items',
    listingItemsCount: 'Listing {count} items',
    all: 'All',
    standard: 'Standard',
    sortBy: 'Sort by',
    unknown: 'Unknown',
    customer: 'Customer',
    generalCustomerSettings: 'General customer settings',
    switchLogin: 'Switch login',
    selectLogin: 'Select login',
    endImpersonation: 'End impersonation',
    country: 'Country',
    brand: 'Brand',
    total: 'Total',
    remaining: 'Remaining',
    totalPrice: 'Total price',
    totalQuantity: 'Total quantity',
    assortmentAdminCostDescription: 'Administration handling fee below',
    quantityAdjusted:
      'Quantity has been adjusted since the quantity per delivered unit is {qtyPerUnit}',
    quantityAdjustedInfo:
      'The quantity of some articles have been adjusted to match the quantity per delivered unit',
    default: 'Default',
    branch: 'Branch',
    email: 'Email',
    telephone: 'Telephone',
    fax: 'Fax',
    reference: 'Reference',
    references: 'Reference(s)',
    showAll: 'Show all',
    currency: 'Currency',
    orderCurrency: 'Order currency',
    status: 'Status',
    type: 'Type',
    export: 'Export',
    project: 'Project',
    projects: 'Project(s)',
    assortment: 'Assortment',
    error: 'Error',
    internalServerError:
      'The issue has been reported and we are working on a solution',
    file: 'File',
    optional: 'Optional',
    download: 'Download',
    save: 'Save',
    show: 'Show',
    chooseFile: 'Choose file',
    createNew: 'Create new',
    created: 'Created',
    language: 'Language',
    name: 'Name',
    done: 'Done',
    add: 'Add',
    remove: 'Remove',
    delete: 'Delete',
    copy: 'Copy',
    clearSelection: 'Clear selection',
    removeConfirmation: 'Remove {name}?',
    deleteConfirmation: 'Delete {name}?',
    selectionCount: '{count} selected',
    confirmation: 'Are you sure?',
    deleteSelected: 'Delete selected',
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
    categories: 'Categories',
    orderTypes: 'Order type(s)',
    deliveryStatuses: 'Delivery status(es)',
    color: 'Color',
    syncSchedule: 'Sync schedule',
  },

  assortment: {
    assortment: 'Assortment',
    assortments: 'Assortments',
    subAssortment: 'Sub-assortment',
    displayFrozenArticles: 'Display frozen articles',
    copyAssortment: 'Copy assortment {assortmentName} from branch {branch}',
    copyAssortmentResult: 'Assortment copy result',
    createAssortment: 'Create assortment',
    createSubAssortment: 'Create sub-assortment',
    addArticles: 'Add products',
    clearArticles: 'Clear products',
    deleteAssortments: 'Delete assortments',
    deleteArticleFromSelected: 'Delete product from assortment',
    useStockLocations: 'Show local multistock only',
    useLocalBranchStock: 'Show local branch stock only',
    allowAutomaticOrder: 'Allow automatic order',
    allowAutomaticOrderOnIncomingStock:
      'Allow incoming stock dates on automatic orders',
    useAutomaticQuantityAdjustment:
      'Automatically adjust cart quantity to match quantity per unit',
    activateAdminPriceThreshold: 'Add administrator cost on checkout',
    assortmentCurrency: 'Currency',
    adminPrice: 'Price',
    currencyDepends: 'Depends on branch',
    adminPriceTriggerThreshold:
      'Remove administrator cost if total order value is above',
    adminPriceCurrency:
      'Price is calculated with the assortments branch currency and will be converted to the customers local currency on checkout',
    draftQuantityAdjustmentPercent:
      'Draft order quantity adjustment allowed in percent',
    assortmentCopied: 'Assortment copied',
    assortmentCopiedSuccess: 'Assortment copied successfully',
    assortmentCreated: 'Assortment created',
    assortmentUpdated: 'Assortment updated',
    assortmentRemoved: 'Assortment deleted',
    assortmentsRemoved: 'Assortments deleted',
    assortmentArticlesAdded: 'Assortment products added',
    assortmentArticlesCleared: 'Assortment products cleared',
    assortmentArticleRemoved: 'Assortment product deleted',
    createChildAssortmentTitle:
      'Create sub-assortment in {parentAssortmentName} ',
    assortmentRemovalWarning:
      'Deleting an assortment will delete customer logins, sub-assortments and products',
    assortmentsRemovalWarning:
      'Deleting assortments will delete its customer logins, sub-assortments and products. This action cannot be undone.',
    assortmentsRemoveConfirmation:
      'You have selected {nrOfRows} row(s) for deletion',
    assortmentsArticleRemoveWarning:
      'The selected product will be removed from the selected assortments. Are you sure?',
    assortmentsArticleRemoveConfirmation:
      'You have selected {nrOfRows} assortment(s)',
    assortmentArticlesMissing: 'Articles missing',
    assortmentArticlesMissingWarning:
      'Some articles could not be copied because they do not exist in the selected branches. Click the download button below to receive a full report.',
    assortmentArticlesMissingExport: 'Export list of missing articles',
  },

  customer: {
    unknown: 'Unknown',
    brand: 'Brand',
    vendor: 'Vendor',
    agent: 'Agent',
    internal: 'Internal',
    customerType: 'Customer type',
    invoiceCustomer: 'Invoice customer',
    adminCostThresholdLabel: 'Admin cost threshold',
    saveGeneralCustomerSettings: 'Save general customer settings',
    customerGeneralSettingsSaved: 'Customer settings saved!',
  },

  documents: {
    documents: 'Documents',
    name: 'Name',
    description: 'Description',
    generalDocuments: 'General documents',
    branchDocuments: 'Documents for {branch}',
    yourDocuments: 'Your documents',
    newDocument: 'New document',
    confirmRemoveDocument: 'Are you sure you want to remove this document?',
    removeDocument: 'Remove document',
    documentRemoved: 'Document removed',
  },

  notifications: {
    notifications: 'Notifications',
    name: 'Title',
    message: 'Message',
    newNotification: 'New notification',
    updateNotification: 'Update notification',
    confirmRemoveNotification:
      'Are you sure you want to remove this notification?',
    removeNotification: 'Remove notification',
    notificationRemoved: 'Notification removed',
  },

  reports: {
    reports: 'Reports',
    articleStatistics: {
      report: 'Product statistics',
      validationMessage: 'Specify branch, category and time period',
      validationMessageBrand: 'Specify category and time period',
    },
    articleCategory: 'Product category',
    branchPlaceholder: 'Select branches',
    categories: {
      clear: 'Clear',
      selectAll: 'Select all',
      filterPlaceholder: 'Filter available categories',
      noMatchingCategories: 'No matching categories',
      selectABranch: 'Select a branch to show categories',
      noCategoriesSelected: 'No categories selected',
    },
    customerOrders: {
      report: 'Customer orders',
      validationMessage:
        'Specify branch, category, customer type and time period',
      validationMessageBrand: 'Specify category and time period',
    },
    customerTypePlaceholder: 'Select customer types',
    downloadReport: 'Download report',
    exportArticles: 'Export articles',
    exportArticleVariants: 'Export articles with variants',
    currentLocation: '(Current location)',
    allLocations: '(All locations)',
    includeFrozenArticles: 'Include frozen products',
    includeImages: 'Include images',
    includeShortageList: 'Include shortage list',
    itemType: 'Item type',
    itemTypeFilter: 'Filter on item type',
    itemTypePlaceholder: 'Select item types',
    timePeriod: 'Time period',
    timePeriodPlaceholder: 'Select a time period',
  },

  article: {
    article: 'Product',
    articles: 'Products',
    articleNumber: 'Product no',
    articleNumberOrDescription: 'Product no or description',
    description: 'Description',
    extraDescription: 'Extra description',
    kit: 'Kit',
    matrix: 'Matrix',
    frozen: 'Frozen',
    frozenAlert: "This article is frozen and can't be ordered",
    size: 'Size',
    unit: 'Unit',
    color: 'Color',
    showSummary: 'Show summary',
    showTopContent: 'Show top content',
    hideSummary: 'Hide summary',
    hideTopContent: 'Hide top content',
    articleInformationTitle: 'Product information',
    articleCategory: 'Product category',
  },

  stock: {
    stock: 'Stock',
    location: 'Location',
    inStock: 'In stock',
    inOrder: 'In order',
    PO: 'PO',
    balance: 'Balance',
    available: 'Available',
  },

  cart: {
    cart: 'Cart',
    quantityInCart: 'Quantity in cart',
    checkout: 'Checkout',
    updateCart: 'Update cart',
    unavailableArticle: 'This product is no longer available',
    removeUnavailableArticles: 'Please remove unavailable products from cart',
  },

  order: {
    order: 'Order',
    orders: 'Orders',
    placeOrder: 'Place order',
    referenceOrderNumber: 'Reference order no',
    message: 'Message',
    careLabelPos: 'CareLabel PO´s',
    orderNumber: 'Order number',
    reference: 'Reference',
    externalReference: 'External reference',
    internalReference: 'Internal reference',
    orderDate: 'Order date',
    confirmedDeliveryDate: 'Confirmed delivery date',
    confirmedDate: 'Confirmed',
    latestDate: 'Latest',
    deliveryDate: 'Delivery date',
    deliveryDates: 'Delivery dates',
    deliveryStatus: 'Delivery status',
    progress: 'Progress',
    progresses: 'Progresses',
    unconfirmed: 'Unconfirmed',
    confirmed: 'Confirmed',
    undelivered: 'Undelivered',
    partiallyDelivered: 'Partially delivered',
    delivered: 'Delivered',
    unplaced: 'Unplaced',
    placed: 'Placed',
    awaitingDelivery: 'Awaiting delivery',
    billedTo: 'Billed to',
    paymentTerms: 'Payment terms',
    deliveryTerms: 'Delivery terms',
    deliveryMethod: 'Delivery method',
    noMessage: 'No message',
    row: 'Row',
    deliveryNote: 'Delivery note',
    invoiceNumber: 'Invoice number',
    placeAsVendor: 'Place order as vendor',
    orderPlacedTitle: 'Order was placed',
    orderTypeForecast: 'Forecast',
    orderTypeStandard: 'Standard',
    orderTypeSample: 'Call Off / SMS',
    orderTypeVariable: 'Carelabel',
    orderPlacedDescription:
      'Your order has now been submitted and will be processed shortly. You can follow the progress of your order by checking your order history',
    trackingNumber: 'AWB number',
    updateOrder: 'Update order',
    orderType: 'Order type',
    placeVariableOrderTitle: 'Carelabel order',
    placeVariableOrderDescription:
      'To check the current status, please follow the PO-links',
    contactName: 'Contact name',
    contactPhone: 'Contact phone',
    courierAccount: 'Courier account',
    selectAddress: 'Select address',
    origin: 'Origin',
    deliveryPickerDisabledTooltipOutOfStock:
      'Only optional if articles are in stock',
    deliveryPickerDisabledTooltipMultiStock:
      'Delivery date selection is not available for one or more products in cart',

    additionalFields: {
      title: 'Additional',
      brandName: 'Brand name',
      garmentType: 'Garment type',
      garmentQty: 'Garment quantity',
      segment: 'Segment',
    },
    season: 'Season',
  },

  address: {
    address: 'Address',
    addresses: 'Addresses',
    customAddress: 'New custom address',
    shipping: 'Shipping',
    billing: 'Billing',
    company: 'Company',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    postalCode: 'Postal code',
    country: 'Country',
    shippingTypeStandard: 'Standard',
    shippingTypeNewCustom: 'New',
    shippingTypeExistingCustom: 'Saved',
  },

  invoice: {
    invoices: 'Invoices',
    invoiceNumber: 'Invoice number',
    invoiceDate: 'Invoice date',
    dueDate: 'Due date',
    paid: 'Paid',
    unpaid: 'Unpaid',
    overdue: 'Overdue',
  },

  login: {
    logout: 'Logout',
    noAvailableLogins: 'No available customer logins',
  },

  session: {
    lockedTitle: 'Your customer account is locked due to overdue invoices',
    lockedDescription:
      'See your statement in the webshop under Invoices. If you have any questions, please contact finance@rudholm-hk.se',
  },

  users: {
    user: 'User',
    users: 'Users',
    name: 'Name',
    email: 'Email',
    userUpdated: 'User updated',
    editUser: 'Edit user',
    customerLogins: 'Customer logins',
    customerLoginRemoveConfirmation: 'Delete this login?',
    customerLoginRemoved: 'Customer login deleted',
    customerLoginUpdated: 'Customer login updated',
    customerLoginsRemoved: 'Customer logins deleted',
    customerLoginsRemovalWarning:
      'Are you sure you want to delete all selected customer logins? This action cannot be undone.',
    customerLoginsRemoveConfirmation:
      'You have selected {nrOfRows} row(s) for deletion',
  },

  branches: {
    branches: 'Branches',
    copyToBranches: 'Copy to branches',
  },

  dashboard: {
    dashboard: 'Dashboard',
    welcome: 'Welcome',
    contactRH: 'Contact Rudholm',
    news: 'Latest news',
    invoices: {
      title: 'Overdue invoices',
      noneDue: 'No invoces are overdue',
      viewAll: 'View all invoices',
      viewAllUnpaid: 'View all unpaid invoices',
    },
  },

  validation: {
    mixed: {
      default: 'Invalid',
      required: 'Required field',
      oneOf: 'Must be one of the following values: {values}',
      notOneOf: 'Must not be one of the following values: {values}',
      notType: 'Must be a {type} type',
    },
    string: {
      length: 'Must be exactly {length} characters',
      min: 'Must be at least {min} characters',
      max: 'Must be at most {max} characters',
      matches: 'Must match the following: "{regex}"',
      email: 'Must be a valid email',
      url: 'Must be a valid URL',
      trim: 'Must be a trimmed string',
      lowercase: 'Must be a lowercase string',
      uppercase: 'Must be a upper case string',
      invalidChars: 'Contains invalid characters',
    },
    number: {
      min: 'Must be greater than or equal to {min}',
      max: 'Must be less than or equal to {max}',
      lessThan: 'Must be less than {less}',
      moreThan: 'Must be greater than {more}',
      notEqual: 'Must be not equal to {notEqual}',
      positive: 'Must be a positive number',
      negative: 'Must be a negative number',
      integer: 'Must be an integer',
    },
    date: {
      min: 'Must be later than {min}',
      max: 'Must be at earlier than {max}',
    },
    object: {
      noUnknown: 'Cannot have keys not specified in the object shape',
    },
    array: {
      min: 'Must have at least {min} items',
      max: 'Must have less than or equal to {max} items',
    },
    file: {
      maxSizeExceeded: 'File exceeds max size of {maxSize}',
      unsupportedFileType: 'File type is unsupported',
      multipleNotAllowed: 'Multiple files is not allowed',
      multipleMaxSizeExceeded: 'Total max size for files is exceeded',
      multipleMaxCountExceeded: 'Too many files selected',
    },
  },
  checkoutAdapter: {
    checkoutAdapters: 'Checkout Adapters',
    _3M: {
      addToCart: 'Add to cart',
      removeFromCart: 'Remove from cart',
      confirmRead: 'I have read, and agree to follow the ',
      confirmReadLink: '3M Scotchlite Product and Brand Usage Standards',
      cartIsFull: 'Your cart is currently full',
      clearCart: 'Clear cart',
      lineItemMessage: 'Cart',
      order: {
        poNumber: '3M PO-number or distributor name',
        brand: 'Brand',
        factory: 'Factory',
        reflectiveProduct: 'Reflective product',
        segment: 'Segment',
        contactName: 'Contact name',
        contactPhone: 'Contact phone',
        courierAccount: 'Courier account',
        message: 'Message',
        producedGarment: 'Produced garment',
        garmentType: 'Garment type',
        garmentQuantity: 'Garment quantity',
        quantity: 'Quantity',
      },
      unavailableProduct: 'Product is no longer available',
    },
  },
  orderMetaFields: {
    confirmRemoveMetaFieldOption:
      'Are you sure you want to remove option {name}?',
    createOrderMetaFieldOption: 'Create option',
    editOrderMetaFields: 'Edit order meta fields',
    isAlreadyParentOption: 'Is already a parent option',
    isSubOption: 'Is suboption',
    orderMetaField: 'Order meta field',
    orderMetaFieldOptionCreated: 'Option {name} created',
    orderMetaFieldOptionRemoved: 'Option {name} removed',
    orderMetaFieldOptionUpdated: 'Option {name} updated',
    parentMetaField: 'Parent meta field',
    parentOption: 'Parent option',
    removeMetaFieldOption: 'Remove option',
    updateOrderMetaFieldOption: 'Update option',
    value: 'Value',
  },
  customers: {
    customers: 'Customers',
  },
  widgets: {
    widgets: 'Widgets',
    widgetType: 'Widget type',
    widgetCreated: 'Widget created',
    widgetUpdated: 'Widget updated',
    widgetRemoved: 'Widget removed',
    widgetNamePlaceholder: 'Name your widget',
    customerMustBeBrand: 'Widgets disabled, customer must be of type "Brand"',
    widgetRemovalWarning:
      'Are you sure you want to remove this widget from your dashboard?',
    exportShortageList: 'Export shortage list',
    articlesInShortage: 'Articles in shortage',
    create: 'Create new widget',
    insufficientCompetence:
      'Insufficient competence for this widget, please remove this widget and recreate a new one.',
    errorRetrievedWidget: 'Could not retrieve widget',
    categories: {
      selectCategory: 'Select a category',
      createCategory: 'Create new category',
      updateCategory: 'Update category',
      deleteCategory: 'Delete category',
      categoryCreated: 'Category created',
      categoryUpdated: 'Category updated',
      categoryRemoved: 'Category removed',
      removalWarning:
        'Are you sure you want to remove this category? This will move all its widgets to uncategorized',
      customerRemovalWarning:
        'Are you sure you want to remove this category? This will delete all the widgets within the category',
      noCategories: 'You have not created any categories',
    },
    lastGeneratedReportFailed:
      'Last generated report failed, please try generating a new one. You can still download the last successfully generated report below.',
    pendingReport: 'There is currently one pending report',
    noReportsGeneratedYet: 'There are no generated reports yet for this widget',
    downloadExport: 'Download latest report',
    generated: 'Generated',
    awaitingDeliveries: {
      create: 'Create awaiting deliveries widget',
      update: 'Update awaiting deliveries widget',
      widgetText: 'Awaiting delivery orders',
      triggerExport: 'Export awaiting deliveries',
    },
    shortage: {
      create: 'Create shortage widget',
      update: 'Update shortage widget',
      triggerExport: 'Export shortage list',
      articlesInShortage: 'Articles in shortage',
    },
    delayedDeliveries: {
      create: 'Create delayed deliveries widget',
      update: 'Update delayed deliveries widget',
      widgetText: 'Delayed delivery orders',
      triggerExport: 'Export delayed deliveries',
    },
    productStatistics: {
      create: 'Create product statistics report widget',
      update: 'Update product statistics report widget',
      triggerExport: 'Trigger new product statistics report',
      newReportTriggered:
        'A new product statistics report is now under construction',
    },
    customerOrdersReport: {
      triggerExport: 'Trigger new customer orders report',
      create: 'Create customer orders report widget',
      update: 'Update customer orders report widget',
      newReportTriggered:
        'A new customer orders report is now under construction',
    },
    slowStock: {
      triggerExport: 'Trigger new slow stock report',
      create: 'Create slow stock widget',
      update: 'Update slow stock widget',
      newReportTriggered: 'A new slow stock report is now under construction',
    },
  },
  alarms: {
    addAlarm: 'Send email notifications',
  },
});
